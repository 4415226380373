
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: flex;
  flex-wrap: wrap;
}


.cardContainer {
  flex: 0 0 100%;

  @include hz-breakpoint-up(m) {
    flex: 0 0 33.333333%;
  }
}

.card {
  margin: hz-spacing(xxs);
  min-height: 212px;
}

.title {
  margin-bottom: hz-spacing(xxs);
}

.link {
  padding: hz-spacing(xxxs) 0;
  color: hz-color(text-primary);
}

.hiddenLinks {
  display: none;
}

.showMore {
  text-align: left;
  margin-top: hz-spacing(xs);

  button {
    display: flex;
  }
}
