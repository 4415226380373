
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.categoryBreadcrumb {
  display: flex;
  align-items: center;
  margin: hz-spacing(s) hz-spacing(xxs);

  .breadcrumbBox {
    display: none;
    @include hz-breakpoint-up(m) {
      display: block;
    }
  }

  .mpSvgArrowLeft {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .mpSvgHome {
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
  }

  .categoryTitle {
    display: flex;
    @include hz-breakpoint-up(m) {
      padding-left: 20px;
      border-left: hz-border(standard) hz-color(border-light);
      margin-left: 20px;
      flex: 1;
    }
  }

  .categoryIconContainer {
    display: inline-flex;
    background-color: hz-color(background-brand-light);
    height: 40px;
    width: 40px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    margin: 0 hz-spacing(s) 0 0;

    @include hz-breakpoint-up(m) {
      height: 68px;
      width: 68px;
    }
  }

  .categoryIcon {
    height: 28px;
    width: 28px;

    @include hz-breakpoint-up(m) {
      height: 40px;
      width: 40px;
    }
  }

  h1 {
    display: inline-flex;
    font-size: 24px;
    line-height: 32px;
    margin: hz-spacing(xxxs) 0;

    @include hz-breakpoint-up(m) {
      font-size: 36px;
      line-height: 60px;
    }
  }
}
