
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  padding: 0 hz-spacing(xxs);
  @include hz-breakpoint-up(m) {
    padding: 0;
  }
}

.Pill {
  display: block;
  width: fit-content;
  @include hz-breakpoint-up(m) {
    display: inline-block;
  }
}
