
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

// Global styles
html,
body {
  @include hz-textStyle(body-regular);
  color: hz-color(text-primary);
}

.Page-container {
  width: 100%;

  .l1-page-section {
    padding: hz-spacing(m) 0;
  }

  .l1-page-section:nth-child(odd) {
    background: hz-color(background-default);
  }
}

.Page-content {
  justify-content: center;
}

.categories-root {
  width: 100%;
}

.feeds-root {
  width: 100%;
  min-height: 400px;
}

.banner-top-dt {
  align-items: center;
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  min-height: 250px;
}
